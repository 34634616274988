import * as React from 'react';


import {
  MainLayout,
  Seo,
  LiturgicSchedule,
  DailyIcon,
  RecentArticles,
  ActivitiesBox,
} from '../components';


const ContactPage = () => {

  const pageTitle = 'Program Liturgic';
  
  return (
    <>
      <Seo title={pageTitle} />
      <MainLayout>
        <article>
          <h1>{pageTitle}</h1>
          <LiturgicSchedule />
        </article>
        <aside>
          <DailyIcon />
          <ActivitiesBox />
          <RecentArticles />
        </aside>
      </MainLayout>
    </>
  );
};

export default ContactPage;


